import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentChips.figmaUrl.android} codeUrl={checklists.componentChips.codeUrl.android} checklists={checklists.componentChips.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Chips allow users to enter information, make selections, filter content, or trigger actions. While buttons are expected to appear consistently and with familiar calls to action, chips should appear dynamically as a group of multiple interactive elements.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/chips-android-1.gif",
            "width": 200,
            "height": 410,
            "align": "center",
            "resize-mode": "contain",
            "alt": "chips usage"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Chip } from '@legion-crossplatform/ui'

<Chip label="This is chip" />
`}</code></pre>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <h3>{`Solid`}</h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": "144px",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/react-native/react-native-chips-solid-1.png",
            "alt": "Solid Primary Chips Legion React Native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Chip } from "@legion-crossplatform/ui";

<Chip label="Solid Primary" variant="primary" />

// or you can just use the default variant
<Chip>Solid primary</Chip>
`}</code></pre>
    <h3>{`Soft`}</h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": "150px",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/react-native/react-native-chips-soft-1.png",
            "alt": "Soft Primary Chips Legion React Native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Chip } from "@legion-crossplatform/ui";

<Chip label="Soft Primary" variant="softPrimary" />
`}</code></pre>
    <h3>{`Outline`}</h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": "300px",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/react-native/react-native-chips-outline-1.png",
            "alt": "Outline Primary Chips Legion React Native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Chip } from "@legion-crossplatform/ui";

<Chip label="Outline Primary" variant="outlinePrimary" />
`}</code></pre>
    <div className="divi" />
    <h2>{`isClosable`}</h2>
    <p>{`Default: `}<inlineCode parentName="p">{`false`}</inlineCode>{`. If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, renders close button action to the right of the Chips`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Chip } from "@legion-crossplatform/ui";

<Chip label="Outline Primary" variant="outlinePrimary" isClosable />
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The primary text to be shown to the user within the Chips`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specifies the color palette of the Chips. Determines how the Chips will look to the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows for custom background colors beyond what is specified by the variant`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isClosable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`. If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, renders close button action to the right of the Chips`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      